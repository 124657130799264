import './calculationTaskResultsView.scoped.scss'
import { PageHeader } from "components/common/pageHeader/pageHeader"
import {
  CalculationTaskPageTemplate,
} from "components/calculationTask/calculationTaskPageTemplate/calculationTaskPageTemplate"
import {
  CalculationTaskPageSidebar,
} from "components/calculationTask/calculationTaskPageSidebar/calculationTaskPageSidebar"
import { LatLongPoint, MapPane } from "components/calculationTask/mapPane/mapPane"
import { BasePageLayout } from "components/layout/basePageLayout/basePageLayout"
import { CalculationTaskResponse } from "services/sicalcApi/responses/calculationTaskResponse"
import { NavbarPageHeading } from "components/calculationTask/navbarPageHeading/navbarPageHeading"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import { CalculationTaskBreadCrumbs } from "components/calculationTask/calculationTaskBreadCrumbs"
import { CalculationResultResponse } from "services/sicalcApi/responses/calculationResultResponse"
import {
  useCallback,
  useEffect,
  useState,
} from "react"
import {
  CalculationTaskSetupViewer,
} from "components/calculationTask/calculationTaskSetupViewer/calculationTaskSetupViewer"
import { CustomScenarioResponse } from "services/sicalcApi/responses/customScenarioResponse"
import { BigActionButton } from "components/common/buttons/bigActionButton/bigActionButton"
import { useModalService } from "services/modal/modalService"
import { ConfirmationModal } from "components/common/modals/confirmationModal/confirmationModal"
import { BuildingResponse } from "services/sicalcApi/responses/buildingResponse"

export interface CalculationTaskResultsViewProps {
  calculationTask: CalculationTaskResponse
  onResetToDraft: () => void
}

export const CalculationTaskResultsView = (props: CalculationTaskResultsViewProps) => {
  const [ calculationResult, setCalculationResult ] = useState<CalculationResultResponse>()
  const [ buildings, setBuildings ] = useState<BuildingResponse[]>([])
  const { calculationTaskApi, buildingApi } = useSicalcApiService()


  const refreshCalculationResult = useCallback(async () => {
    const response = await calculationTaskApi.getResults(props.calculationTask.id)
    setCalculationResult(response)
  }, [calculationTaskApi, props.calculationTask.id])

  const refreshBuildings = useCallback(async () => {
    const response = await buildingApi.getAll(props.calculationTask.id)
    setBuildings(response.buildings)
  }, [buildingApi, props.calculationTask.id])

  useEffect(() => {
    refreshCalculationResult()
  }, [refreshCalculationResult])

  useEffect(() => {
    refreshBuildings()
  }, [refreshBuildings])

  const noiseSourceLocation: LatLongPoint = {
    latitude: props.calculationTask.noiseSource!.latitude,
    longitude: props.calculationTask.noiseSource!.longitude,
  }
  const [ customScenarios, setCustomScenarios ] = useState<CustomScenarioResponse[]>()
  const { customScenarioApi } = useSicalcApiService()
  const { showModal } = useModalService()
  const refreshCustomScenarios = useCallback(async () => {
    const response = await customScenarioApi.getAll(props.calculationTask.id)
    setCustomScenarios(response.customScenarios)
  }, [customScenarioApi, props.calculationTask.id])

  useEffect(() => {
    refreshCustomScenarios()
  }, [refreshCustomScenarios])

  const onEditButtonClicked = async () => {
    const confirmed = await showModal(context =>
      <ConfirmationModal
        modalContext={context}
        heading="Resultatet blir slettet"
        message="Ved å åpne beregningen for redigering slettes det nåværende resultatet."
        confirmButtonText="Fortsett"
        confirmButtonStyle="danger"
      />) as boolean

    if (!confirmed) {
      return
    }

    await calculationTaskApi.resetToDraft(props.calculationTask.id)
    props.onResetToDraft()
  }

  return (
    <BasePageLayout
      header={
        <PageHeader>
          <NavbarPageHeading>{props.calculationTask.name}</NavbarPageHeading>
        </PageHeader>
      }
    >
      <CalculationTaskPageTemplate
        sidebar={
          <CalculationTaskPageSidebar
            breadcrumbs={(<CalculationTaskBreadCrumbs calculationTask={props.calculationTask} />)}
          >
            <div className="completed-notification">
              Beregningen er utført.
            </div>
            <CalculationTaskSetupViewer
              calculationTask={props.calculationTask}
              customScenarios={customScenarios}
              buildings={buildings}
            />
            <div className="bottom-aligned column justify-end gap4">
              <BigActionButton
                color="yellow"
                onClick={onEditButtonClicked}
                text="Rediger"
              />
              <BigActionButton
                onClick={() => calculationTaskApi.triggerResultsFileDownload(props.calculationTask.id)}
                text="Last ned resultater"
              />
            </div>
          </CalculationTaskPageSidebar>
        }
      >
        <MapPane
          mapLocation={noiseSourceLocation}
          calculationResult={calculationResult}
          calculationArea={props.calculationTask.calculationArea ?? undefined}
        />
      </CalculationTaskPageTemplate>
    </BasePageLayout>
  )
}

