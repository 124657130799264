import { AxiosInstance } from "axios"
import { BuildingListResponse } from "services/sicalcApi/responses/buildingListResponse"
import { BuildingResponse } from "services/sicalcApi/responses/buildingResponse"
import { CreateOrUpdateBuildingRequest } from "services/sicalcApi/requests/createOrUpdateBuildingRequest"
import { GenerateFacadePointsRequest } from "services/sicalcApi/requests/generateFacadePointsRequest"
import { GenerateFacadePointsResponse } from "services/sicalcApi/responses/generateFacadePointsResponse"

export class BuildingApi {
  private sicalcClient: AxiosInstance

  constructor(sicalcClient: AxiosInstance) {
    this.sicalcClient = sicalcClient
  }

  public async getAll(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/buildings`
    const response = await this.sicalcClient.get<BuildingListResponse>(url)
    return response.data
  }

  public async create(calculationTaskId: string, request: CreateOrUpdateBuildingRequest) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/buildings`
    const response = await this.sicalcClient.post<BuildingResponse>(url, request)
    return response.data
  }

  public async update(calculationTaskId: string, buildingId: string, request: CreateOrUpdateBuildingRequest) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/buildings/${buildingId}`
    const response = await this.sicalcClient.put<BuildingResponse>(url, request)
    return response.data
  }

  public async delete(calculationTaskId: string, buildingId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/buildings/${buildingId}`
    await this.sicalcClient.delete(url)
  }

  public async generateFacadePoints(request: GenerateFacadePointsRequest) {
    const url = `/api/v1/buildings/generate-facade-points`
    const response = await this.sicalcClient.post<GenerateFacadePointsResponse>(url, request)
    return response.data
  }
}

