import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import 'components/calculationTask/createOrEditBuildingModal/ntmVertexInputFieldSet/ntmVertexInputFieldSet.scoped.scss'
import { TextInput } from "components/common/formFields/textInput/textInput"
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import {
  FormikErrors,
  FormikTouched,
  useFormik,
} from "formik"
import * as yup from "yup"
import {
  VertexInputFieldSetHandle,
  VertexInputFieldSetProps,
  VertexInputFieldValues,
} from "components/calculationTask/createOrEditBuildingModal/vertexInputTypes"
import { Vertex } from "services/sicalcApi/sharedEntities/vertex"
import {
  altitudeValidator,
  eastingValidator,
  northingValidator,
} from "utils/validators/coordinateValidators"
import {
  UtmToGeographicCoordinateConversionRequest,
} from "services/sicalcApi/requests/utmToGeographicCoordinateConversionRequest"
import { UtmHemisphere } from "services/sicalcApi/sharedEntities/utmCoordinates"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import { SelectField } from "components/common/formFields/selectField/selectField"
import { ToolTip } from "components/common/toolTip/toolTip"
import { TooltipTriggerIcon } from "components/common/tooltipTriggerIcon/tooltipTriggerIcon"
import { useMapService } from "services/mapService/mapService"
import { useAppEditionService } from "services/appEdition/appEditionService"
import papaParse from "papaparse"
import { toast } from "react-hot-toast"
import clipboard from "clipboardy"
import { parseUtmCoordinates } from "components/calculationTask/createOrEditBuildingModal/parseClipboardContent"
import {
  VertexInputFieldsToolbar,
} from "components/calculationTask/createOrEditBuildingModal/vertexInputFieldsToolbar/vertexInputFieldsToolbar"
import {
  GeographicToUtmCoordinateConversionRequest,
} from "services/sicalcApi/requests/geographicToUtmCoordinateConversionRequest"
import { NtmZone } from "services/sicalcApi/sharedEntities/ntmCoordinates"
import {
  NtmToGeographicCoordinateConversionRequest
} from "services/sicalcApi/requests/ntmToGeographicCoordinateConversionRequest"
import {
  GeographicToNtmCoordinateConversionRequest
} from "services/sicalcApi/requests/geographicToNtmCoordinateConversionRequest"

export interface NtmVertex {
  easting: number
  northing: number
  altitude: number
}

interface FormDataModel {
  zone?: NtmZone
  vertices: NtmVertex[]
}

const vertexValidationSchema = yup.object()
  .shape({
    easting: eastingValidator,
    northing: northingValidator,
    altitude: altitudeValidator,
  })

const validationSchema = yup.object()
  .shape({
    zone: yup.number()
      .required("Påkrevd"),
    vertices: yup.array()
      .of(vertexValidationSchema),
  })

export const NtmVertexInputFieldSet = forwardRef((props: VertexInputFieldSetProps, ref: Ref<VertexInputFieldSetHandle>) => {
  const [ initialValues, setInitialValues ] = useState<FormDataModel>({
    vertices: [],
    zone: undefined,
  })
  const [ conversionRequestIsPending, setConversionRequestIsPending ] = useState(false)
  const mapService = useMapService()
  const appEditionService = useAppEditionService()

  const form = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: () => Promise.resolve(true),
    enableReinitialize: true,
  })

  const { coordinateConversionApi } = useSicalcApiService()

  const convertVerticesToGeographicCoordinates = useCallback(async (utmVertices: NtmVertex[], zone: NtmZone) => {
    const conversionRequest: NtmToGeographicCoordinateConversionRequest = {
      data: utmVertices.map(v => ({
        zone: zone!,
        easting: Number(v.easting),
        northing: Number(v.northing),
        hemisphere: UtmHemisphere.N,
      })),
    }
    const conversionResponse = await coordinateConversionApi.convertNtmToGeographic(conversionRequest)
    // TODO: The items in the response are not guaranteed to be in the same order as in the request
    const vertices: Vertex[] = conversionResponse.data
      .map((coords, index) => ({
        ...coords,
        altitude: Number(utmVertices[index].altitude!),
      }))
    return vertices
  }, [ coordinateConversionApi ])

  const getValues: () => Promise<VertexInputFieldValues> = async () => {
    const success: true | undefined = await form.submitForm()
    if (!success) {
      return {
        validationSucceeded: false,
      }
    }

    const convertedVertices = await convertVerticesToGeographicCoordinates(
      form.values.vertices
        .map(v => vertexValidationSchema.cast(v)) as NtmVertex[],
      form.values.zone as NtmZone,
    )

    return {
      validationSucceeded: true,
      vertices: convertedVertices,
    }
  }

  useImperativeHandle(ref, () => ({
    getValues,
  }))

  const getNtmZoneOfMapCenter = () => {
    const mapCenter = mapService.getMapCenter()
    const zone = Math.ceil((mapCenter.longitude))

    if (zone < 5) {
      return 5
    }

    if (zone > 30) {
      return 30
    }

    return zone
  }

  useEffect(() => {
    if (form.values.zone === undefined) {
      const zone = getNtmZoneOfMapCenter()
      onZoneChanged(zone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    // Trigger the props.onchange handler
    const validVertices = form.values.vertices
      .filter(v => vertexValidationSchema.isValidSync(v))
      .map(v => vertexValidationSchema.cast(v))
    convertVerticesToGeographicCoordinates(
      validVertices as NtmVertex[],
      form.values.zone as NtmZone,
    ).then(geographicVertices => {
      props.onChange(geographicVertices)
    })
    // Do not update on props changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ convertVerticesToGeographicCoordinates, form.values.vertices, form.values.zone])

  const getEmptyRow = () => ({
    easting: undefined,
    northing: undefined,
    altitude: undefined,
  })

  const addFormRow = () => {
    const vertex: Partial<NtmVertex> = getEmptyRow()
    form.setFieldValue('vertices', [...form.values.vertices, vertex])
  }

  const removeFormRow = (index: number) => {
    const newErrors = form.errors.vertices ? [...form.errors.vertices as string[]] : []
    newErrors.splice(index, 1)
    form.setErrors({
      ...form.errors,
      vertices: newErrors,
    })

    const newTouched = form.touched.vertices ? [...form.touched.vertices] : []
    newTouched.splice(index, 1)
    form.setTouched({
      ...form.touched,
      vertices: newTouched,
    })

    const { vertices } = form.values
    const newVertices = [...vertices]
    newVertices.splice(index, 1)
    form.setFieldValue('vertices', newVertices)
  }

  const getRowTouchedStatus = (index: number) => {
    if (!form.touched.vertices) {
      return undefined
    }

    return form.touched.vertices[index] as FormikTouched<NtmVertex>
  }

  const getRowErrors = (index: number) => {
    if (!form.errors.vertices) {
      return undefined
    }

    return form.errors.vertices[index] as FormikErrors<NtmVertex>
  }

  const convertInitialVertices = async (ntmZone: NtmZone) => {
    setConversionRequestIsPending(true)
    const request: GeographicToNtmCoordinateConversionRequest = {
      zone: ntmZone,
      data: props.initialValue.map(c => ({
        latitude: c.latitude,
        longitude: c.longitude,
      })),
    }
    try {
      const response = await coordinateConversionApi.convertGeographicToNtm(request)
      // TODO: The items in the response are not guaranteed to be in the same order as in the request
      const result = response.data.map((v, index) => ({
        easting: v.easting,
        northing: v.northing,
        altitude: props.initialValue[index].altitude,
      }))

      return result
    } finally {
      setConversionRequestIsPending(false)
    }
  }

  const onZoneChanged = async (newZone: number) => {
    // Update the value in the form field for user feedback,
    // but using resetForm to avoid a flash of dirty state
    form.resetForm({
      values: {
        ...form.values,
        zone: newZone,
      },
    })
    if (props.initialValue.length > 0) {
      const initialVertices = await convertInitialVertices(newZone)
      setInitialValues({
        zone: newZone,
        vertices: initialVertices,
      })
    } else {
      setInitialValues({
        zone: newZone,
        vertices: [ getEmptyRow() as Partial<NtmVertex> as NtmVertex ],
      })
    }
  }

  const copyToClipboard = async () => {
    const text = papaParse.unparse(form.values.vertices, {
      delimiter: "\t",
      header: false,
    })
    try {
      await clipboard.write(text)
    } catch {
      toast.error("Fikk ikke tilgang til utklippstavlen")
      return
    }
  }

  const onPasteButtonClicked = async () => {
    let text: string
    try {
      text = await clipboard.read()
    } catch {
      toast.error("Fikk ikke tilgang til utklippstavlen. Du kan lime rett inn i en celle i stedet")
      return
    }
    const vertices = parseUtmCoordinates(text)

    if (vertices === null) {
      toast.error("Fant ingen punkter i utklippstavlen")
      return
    }

    insertVertices(vertices, 0)
  }

  const insertVertices = (vertices: NtmVertex[], startingRowIndex: number) => {
    const existingItemsBeforeCurrentRow = form.values.vertices.slice(0, startingRowIndex)
    const existingItemsAfterCurrentRow = form.values.vertices.slice(startingRowIndex + vertices.length)
    form.setFieldValue('vertices', [
      ...existingItemsBeforeCurrentRow,
      ...vertices,
      ...existingItemsAfterCurrentRow,
    ])
  }

  const resetForm = () => {
    form.resetForm()
  }

  const deleteAllVertices = () => {
    form.setFieldValue('vertices', [getEmptyRow()])
    form.setTouched({})
  }

  const onPasteInTextInput = (event: React.ClipboardEvent, rowIndex: number) => {
    const clipboardContent = event.clipboardData.getData("text")
    const vertices = parseUtmCoordinates(clipboardContent)

    if (vertices === null) {
      return
    }

    event.preventDefault()
    insertVertices(vertices, rowIndex)
  }

  return (
    <div className="column gap4">
      <div className="zone-dropdown-wrapper row align-end">
        <SelectField
          label={"Sone"}
          value={form.values.zone}
          name={"zone"}
          onChange={(e) => onZoneChanged(Number(e.target.value))}
          isInvalid={!!form.touched.zone && !!form.errors.zone}
          errors={form.errors.zone}
          options={
            Object.values(NtmZone)
              .filter(item => !isNaN(Number(item)))
              .map(z => ({
                name: z as string,
                value: z as number,
              }))}
          disabled={conversionRequestIsPending || form.dirty}
        />
        {form.dirty && (
          <div className="form-dirty-warning row align-center">
            <ToolTip
              content="Sonen låses når du har gjort endringer i beregningspunktene"
            >
              <TooltipTriggerIcon />
            </ToolTip>
          </div>
        )}
        {conversionRequestIsPending && (
          <div className="mb3 ml4">Omformer ...</div>
        )}
        {form.values.zone === undefined && !conversionRequestIsPending && (
          <div className="select-a-zone-prompt">Velg sone for å fortsette</div>
        )}
      </div>

      {form.values.zone !== undefined && (
        <div className="column gap2">
          <div className="form-label">Hjørnepunkter </div>
          <div className="mb2">
            <VertexInputFieldsToolbar
              onClickCopyToClipboard={copyToClipboard}
              onClickPaste={onPasteButtonClicked}
              onClickResetForm={resetForm}
              canResetForm={form.dirty}
              onClickDeleteAll={deleteAllVertices}
              canDeleteAll={form.values.vertices.length > 0} />
          </div>
          <div className="vertex-input-table column">
            {form.values.vertices.length > 0 && (
              <div className="table-head">
                <span className="table-head-cell"></span>
                <span className="table-head-cell">Øst</span>
                <span className="table-head-cell">Nord</span>
                <span className="table-head-cell">Høyde (m)</span>
              </div>
            )}
            {form.values.vertices.map((vertex, index) => (
              <div className="table-row" key={index}>
                <div className="table-cell">
                  <span className="row-number">{index + 1}</span>
                </div>
                <div className="table-cell">
                  <TextInput
                    name={`vertices[${index}].easting`}
                    value={vertex.easting ?? ''}
                    isInvalid={!!getRowTouchedStatus(index)?.easting && !!getRowErrors(index)?.easting}
                    errors={getRowErrors(index)?.easting}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    onPaste={event => onPasteInTextInput(event, index)}
                  />
                </div>
                <div className="table-cell">
                  <TextInput
                    name={`vertices[${index}].northing`}
                    value={vertex.northing ?? ''}
                    isInvalid={!!getRowTouchedStatus(index)?.northing && !!getRowErrors(index)?.northing}
                    errors={getRowErrors(index)?.northing}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    onPaste={event => onPasteInTextInput(event, index)}
                  />
                </div>
                <div className="table-cell">
                  <TextInput
                    name={`vertices[${index}].altitude`}
                    value={vertex.altitude ?? ''}
                    isInvalid={!!getRowTouchedStatus(index)?.altitude && !!getRowErrors(index)?.altitude}
                    errors={getRowErrors(index)?.altitude}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    onPaste={event => onPasteInTextInput(event, index)}
                  />
                </div>
                <span className="ml4 mr1">
                  <ClearButton
                    onClick={() => removeFormRow(index)}
                    iconName="delete"
                    text="Slett"
                  />
                </span>
              </div>
            ))}
          </div>
          <div className="row">
            <ClearButton
              iconName={"plus"}
              text="Legg til punkt"
              onClick={addFormRow}
            />
          </div>
        </div>
      )}
    </div>
  )
})

