import {
  useCallback,
  useEffect,
  useRef,
} from "react"
import './mapPane.scoped.scss'
import MapRenderer from "components/calculationTask/mapRenderer"
import { CalculationResultResponse } from "services/sicalcApi/responses/calculationResultResponse"
import { useMapService } from "services/mapService/mapService"
import { useAppEditionService } from "services/appEdition/appEditionService"
import { BuildingResponse } from "services/sicalcApi/responses/buildingResponse"

export interface CalculationArea {
  northWest: LatLongPoint
  northEast: LatLongPoint
  southEast: LatLongPoint
  southWest: LatLongPoint
}

export interface LatLongPoint {
  latitude: number
  longitude: number
}

export interface CalculationPoint extends LatLongPoint {
  name?: string
}

export interface MapClickedEvent {
  location: LatLongPoint
}


export interface MapPaneProps {
  mapLocation?: LatLongPoint
  calculationResult?: CalculationResultResponse
  calculationPoints?: CalculationPoint[]
  buildings?: BuildingResponse[]
  calculationArea?: CalculationArea
  onMapClicked?: (e: MapClickedEvent) => void
  onNewCalculationArea?: (calculationArea: CalculationArea) => void
  onNewCalculationPoint?: (calculationPoint: LatLongPoint) => void
  onDeleteCalculationPointClicked?: (calculationPoint: LatLongPoint) => void
  showDrawingTools?: boolean
}

export const MapPane = (props: MapPaneProps) => {
  const mapRoot = useRef(null)
  const renderer = useRef<MapRenderer>()
  const { registerMapRenderer, unRegisterMapRenderer } = useMapService()
  const appEditionService = useAppEditionService()

  useEffect(() => {
    const rendererOptions = {
      tileLayerUrl: appEditionService.settings.map.tileLayerUrl,
      attribution: appEditionService.settings.map.attributionHtml,
      onMapClicked: props.onMapClicked,
      onDeleteCalculationPointClicked: props.onDeleteCalculationPointClicked,
      onNewCalculationArea: props.onNewCalculationArea,
      onNewCalculationPoint: props.onNewCalculationPoint,
      showDrawingTools: props.showDrawingTools,
    }

    const destroy = () => {
      renderer.current?.destroy()
      renderer.current = undefined
      unRegisterMapRenderer()
    }

    destroy()
    renderer.current = new MapRenderer(mapRoot.current!, rendererOptions)
    registerMapRenderer(renderer.current)
    return destroy
    // Adding props.onMapClicked to the dependencies seems to break rendering with React Strict mode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ appEditionService.settings ])

  const goToLocation = useCallback(() => {
    if (!props.mapLocation) {
      return
    }
    renderer.current?.goTo(props.mapLocation, 13)
    // Adding app edition service to the dependencies helps refresh the map when app edition service changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.mapLocation, appEditionService.settings ])

  const renderCalculationPoints = useCallback(() => {
    if (!props.calculationPoints) {
      return
    }
    renderer.current?.renderCalculationPoints(props.calculationPoints)
  }, [ props.calculationPoints ])

  const renderBuildings = useCallback(() => {
    if (!props.buildings) {
      return
    }
    renderer.current?.renderBuildings(props.buildings)
  }, [ props.buildings ])

  const renderCalculationArea = useCallback(() => {
    renderer.current?.renderCalculationArea(props.calculationArea)
  }, [ props.calculationArea ])

  const renderNoiseAreas = useCallback(() => {
    if (!props.calculationResult) {
      return
    }

    renderer.current?.renderResult(props.calculationResult)
  }, [ props.calculationResult ])

  useEffect(() => {
    goToLocation()
  }, [ goToLocation ])

  useEffect(() => {
    renderCalculationPoints()
  }, [ renderCalculationPoints ])

  useEffect(() => {
    renderBuildings()
  }, [ renderBuildings ])

  useEffect(() => {
    renderCalculationArea()
  }, [ renderCalculationArea ])

  useEffect(() => {
    renderNoiseAreas()
  }, [ renderNoiseAreas ])

  return (
    <div className="map-pane" ref={mapRoot}></div>
  )
}

