import './importCalculationPointsForm.scoped.scss'
import {
  FormikErrors,
  FormikTouched,
  useFormik,
} from "formik"
import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
} from "react"
import { TextInput } from "components/common/formFields/textInput/textInput"
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import {
  CalculationAltitudePicker,
} from "components/calculationTask/calculationAltitudePicker/calculationAltitudePicker"
import {
  parseGeographicCoordinates,
} from "components/calculationTask/importCalculationPointsForm/parseClipboardContent"
import papaParse from "papaparse"
import { toast } from "react-hot-toast"
import { CalculationPoint } from "components/calculationTask/calculationTaskSetupView/formDataModel"
import clipboard from 'clipboardy'
import {
  CalculationPointFormDataModel,
  calculationPointValidationSchema,
} from "components/calculationTask/importCalculationPointsForm/calculationPointFormDataModel"
import { coereceToStringOrUndefined } from "utils/stringOperations"


export interface ImportCalculationPointsFormProps {
  initialCalculationPoints: CalculationPoint[]
  onSubmit: (newCalculationPoints: CalculationPoint[]) => void
  onDirtyStateChanged: (isDirty: boolean) => void
}

export interface ImportCalculationPointsFormHandle {
  submit: () => void
}

const _ImportCalculationPointsForm = (props: ImportCalculationPointsFormProps, ref: Ref<ImportCalculationPointsFormHandle>) => {
  useImperativeHandle(ref, () => ({
    submit: form.submitForm,
  }))

  const initialValues: CalculationPointFormDataModel = {
    calculationPoints: props.initialCalculationPoints.length > 0
      ? [...props.initialCalculationPoints]
      :  [{
        name: undefined,
        latitude: '' as unknown as number,
        longitude: '' as unknown as number,
        altitude: undefined,
      }]
    ,
  }

  const handleSubmission = (values: CalculationPointFormDataModel) => {
    const formData = calculationPointValidationSchema
      .cast(form.values) as CalculationPointFormDataModel
    props.onSubmit(formData.calculationPoints.map(c => ({
      ...c,
      name: coereceToStringOrUndefined(c.name),
    })))
  }

  const form = useFormik({
    initialValues,
    validationSchema: calculationPointValidationSchema,
    onSubmit: handleSubmission,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  })

  useEffect(() => {
    props.onDirtyStateChanged(form.dirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.dirty])

  const resetForm = () => {
    form.resetForm()
  }

  const onPasteButtonClicked = async () => {
    let text: string
    try {
      text = await clipboard.read()
    } catch {
      toast.error("Fikk ikke tilgang til utklippstavlen. Du kan lime en tabell inn i en celle i stedet")
      return
    }
    const calculationPoints = parseGeographicCoordinates(text)

    if (calculationPoints === null) {
      toast.error("Fant ingen beregningspunkter i utklippstavlen")
      return
    }

    addCalculationPoints(calculationPoints, 0)
  }

  const onPasteInTextInput = (event: React.ClipboardEvent, rowIndex: number) => {
    const clipboardContent = event.clipboardData.getData("text")
    const calculationPoints = parseGeographicCoordinates(clipboardContent)

    if (calculationPoints === null) {
      return
    }

    event.preventDefault()
    addCalculationPoints(calculationPoints, rowIndex)
  }

  const addCalculationPoints = (calculationPoints: CalculationPoint[], startingRowIndex: number) => {
    const existingItemsBeforeCurrentRow = form.values.calculationPoints.slice(0, startingRowIndex)
    const existingItemsAfterCurrentRow = form.values.calculationPoints.slice(startingRowIndex + calculationPoints.length)
    form.setFieldValue('calculationPoints', [
      ...existingItemsBeforeCurrentRow,
      ...calculationPoints,
      ...existingItemsAfterCurrentRow,
    ])
  }

  const addFormRow = () => {
    form.setFieldValue('calculationPoints', [
      ...form.values.calculationPoints,
      {
        latitude: '',
        longitude: '',
      }],
    )
  }

  const removeFormRow = (index: number) => {
    const { calculationPoints } = form.values
    const newCalculationPoints = [...calculationPoints]
    newCalculationPoints.splice(index, 1)
    form.setFieldValue('calculationPoints', newCalculationPoints)
  }

  const copyToClipboard = async () => {
    const values = form.values.calculationPoints.map(c => ({
      name: c.name,
      latitude: c.latitude,
      longitude: c.longitude,
      altitude: c.altitude,
    }))
    let text: string
    try {
      text = papaParse.unparse(values, {
        delimiter: "\t",
        header: false,
      })
    } catch {
      toast.error("Fikk ikke tilgang til utklippstavlen")
      return
    }

    await clipboard.write(text)
  }

  const getRowTouchedStatus = (index: number) => {
    if (!form.touched.calculationPoints) {
      return undefined
    }

    return form.touched.calculationPoints[index] as FormikTouched<CalculationPoint>
  }

  const getRowErrors = (index: number) => {
    if (!form.errors.calculationPoints) {
      return undefined
    }

    return form.errors.calculationPoints[index] as FormikErrors<CalculationPoint>
  }

  return (
    <form className="column gap4" onSubmit={form.handleSubmit}>
      <div className="row gap4">
        <ClearButton iconName="content-copy" onClick={copyToClipboard}>Kopier</ClearButton>
        <ClearButton iconName="content-paste" onClick={onPasteButtonClicked}>Lim inn</ClearButton>
        <ClearButton iconName="restore" onClick={resetForm}>Tilbakestill</ClearButton>
        <ClearButton
          iconName="delete"
          onClick={() => form.setFieldValue('calculationPoints', [])} disabled={!form.values.calculationPoints.length}
        >Slett alle</ClearButton>
      </div>
      <div className="calculation-point-input-form">
        <div className="table-head">
          <span className="table-head-cell">Navn</span>
          <span className="table-head-cell">Breddegrad</span>
          <span className="table-head-cell">Lengdegrad</span>
          <span className="table-head-cell">Høyde (m)</span>
        </div>
        {React.Children.toArray(form.values.calculationPoints.map((c, index) => (
          <div className="table-row">
            <div className="table-cell">
              <TextInput
                name={`calculationPoints[${index}].name`}
                value={c.name ?? ''}
                isInvalid={!!getRowTouchedStatus(index)?.name && !!getRowErrors(index)?.name}
                errors={getRowErrors(index)?.name}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                onPaste={event => onPasteInTextInput(event, index)}
              />
            </div>
            <div className="table-cell">
              <TextInput
                name={`calculationPoints[${index}].latitude`}
                value={c.latitude}
                isInvalid={!!getRowTouchedStatus(index)?.latitude && !!getRowErrors(index)?.latitude}
                errors={getRowErrors(index)?.latitude}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                onPaste={event => onPasteInTextInput(event, index)}
              />
            </div>
            <div className="table-cell">
              <TextInput
                name={`calculationPoints[${index}].longitude`}
                value={c.longitude}
                isInvalid={!!getRowTouchedStatus(index)?.longitude && !!getRowErrors(index)?.longitude}
                errors={getRowErrors(index)?.longitude}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                onPaste={event => onPasteInTextInput(event, index)}
              />
            </div>
            <div className="table-cell">
              <CalculationAltitudePicker
                value={c.altitude ?? 4}
                onChange={value => form.setFieldValue(`calculationPoints[${index}].altitude`, value)}
              />
            </div>
            <span className="ml4 mr1">
              <ClearButton
                onClick={() => removeFormRow(index)}
                iconName="delete"
                text="Slett"
              />
            </span>

          </div>
        )))}
      </div>
      <div className="row">
        <ClearButton
          iconName={"plus"}
          text="Legg til punkt"
          onClick={addFormRow}
        />
      </div>
    </form>
  )
}

export const ImportCalculationPointsForm = forwardRef(_ImportCalculationPointsForm)
