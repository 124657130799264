import * as yup from "yup"

export const latitudeValidator =  yup.number()
  .transform(replaceDecimalSeparator)
  .required("Påkrevd")
  .typeError("Må være et tall")
  .test("mustBeLatitudeCoordinate", "Må være breddegrad", value => {
    return value !== undefined
      && !isNaN(value)
      && isFinite(value)
      && Math.abs(value) <= 90
  })

export const longitudeValidator = yup.number()
  .transform(replaceDecimalSeparator)
  .required("Påkrevd")
  .typeError("Må være et tall")
  .test("mustBeLongitudeCoordinate", "Må være lengdegrad", value => {
    return value !== undefined
      && !isNaN(value)
      && isFinite(value)
      && Math.abs(value) <= 180
  })

export const altitudeValidator = yup.number()
  .transform(replaceDecimalSeparator)
  .required("Påkrevd")
  .typeError("Må være et tall")
  .min(0, "Må være positiv")

export const eastingValidator = yup.number()
  .transform(replaceDecimalSeparator)
  .required("Påkrevd")
  .typeError("Må være et tall")
  .test("mustBeEastingCoordinate", "Må være østkoordinat", value => {
    return value !== undefined
      && !isNaN(value)
      && isFinite(value)
      && 0 <= value
      && value < 1e6
  })
export const northingValidator = yup.number()
  .transform(replaceDecimalSeparator)
  .required("Påkrevd")
  .typeError("Må være et tall")
  .test("mustBeNorthingCoordinate", "Må være nordkoordinat", value => {
    return value !== undefined
      && !isNaN(value)
      && isFinite(value)
      && 0 < value
      && value < 1e7
  })

/**
 * Allow commas or periods as decimal separator. One is converted to the other,
 * depending on what the user's browser expects
 */
export function replaceDecimalSeparator(currentValue: number, originalValue: string | number) {
  const browserDecimalSeparator = (1.1).toLocaleString().substring(1, 2)
  // We only want to do any replacement if the current value is not a valid number
  // and the original value is a string
  if (!isNaN(currentValue) || typeof originalValue === 'number') {
    return currentValue
  }
  if (browserDecimalSeparator === ',') {
    const result = originalValue.replace('.', ',')
    return Number(result)
  }
  if (browserDecimalSeparator === '.') {
    const result = originalValue.replace(',', '.')
    return Number(result)
  }

  return currentValue
}
