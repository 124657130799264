import './calculationTaskSetupView.scoped.scss'
import {
  CalculationTaskPageTemplate,
} from "components/calculationTask/calculationTaskPageTemplate/calculationTaskPageTemplate"
import {
  CalculationTaskPageSidebar,
} from "components/calculationTask/calculationTaskPageSidebar/calculationTaskPageSidebar"
import {
  CalculationArea,
  LatLongPoint,
  MapClickedEvent,
  MapPane,
} from "components/calculationTask/mapPane/mapPane"
import { CalculationTaskResponse } from "services/sicalcApi/responses/calculationTaskResponse"
import { BasePageLayout } from "components/layout/basePageLayout/basePageLayout"
import { PageHeader } from "components/common/pageHeader/pageHeader"
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { NoiseSourceResponse, NoiseSourceResponse_Scenario } from "services/sicalcApi/responses/noiseSourceResponse"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import { FormikProvider, useFormik } from "formik"
import { ScenarioPicker } from "components/calculationTask/scenarioPicker/scenarioPicker"
import {
  UpdateCalculationTaskRequest,
  UpdateCalculationTaskRequest_WorstCaseScenarioSettings,
} from "services/sicalcApi/requests/updateCalculationTaskRequest"
import { FormSectionHeading } from "components/common/headings/formSectionHeading/formSectionHeading"
import {
  CalculationTaskTitleEditor,
} from "components/calculationTask/calculationTaskTitleEditor/calculationTaskTitleEditor"
import { HeaderContextMenuButton } from "components/common/buttons/dropdownMenuButton/headerContextMenuButton"
import { CustomScenarioResponse } from "services/sicalcApi/responses/customScenarioResponse"
import { SearchableSelectField } from "components/common/formFields/SearchableSelectField/SearchableSelectField"
import { FormFieldAutoSaver } from 'components/formFieldAutoSaver/formFieldAutoSaver'
import { CalculationTaskBreadCrumbs } from "components/calculationTask/calculationTaskBreadCrumbs"
import { HeaderContextMenu } from "components/headerContextMenu/headerContextMenu"
import { AreaSelectionModePicker } from "components/calculationTask/areaSelectionModePicker/areaSelectionModePicker"
import { AreaSelectionMode, GridResolution } from "services/sicalcApi/sharedEntities/sicalcParameters"
import { useMapService } from "services/mapService/mapService"
import {
  CalculationPoint,
  FormDataModel,
  generateValidationSchema,
} from "components/calculationTask/calculationTaskSetupView/formDataModel"
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import { useEscapeKeyHandler } from "hooks/useEscapeKeyHandler"
import {
  CalculationResolutionPicker,
} from "components/calculationTask/CalculationResolutionPicker/CalculationResolutionPicker"
import {
  CalculationAltitudePicker,
} from "components/calculationTask/calculationAltitudePicker/calculationAltitudePicker"
import { useModalService } from "services/modal/modalService"
import {
  ImportCalculationPointsModal,
  ImportCalculationPointsModalResult,
} from "components/calculationTask/importCalculationPointsModal/importCalculationPointsModal"
import {
  CalculationPointSidebarList,
} from "components/calculationTask/calculationPointSidebarList/calculationPointSidebarList"
import { CoordinateSystem } from "services/sicalcApi/sharedEntities/coordinateSystem"
import { SridInput } from "components/calculationTask/sridInput/sridInput"
import { CheckboxInput } from "components/common/formFields/CheckboxInput/CheckboxInput"
import { BigActionButton } from "components/common/buttons/bigActionButton/bigActionButton"
import { toast } from "react-hot-toast"
import { Link } from "react-router-dom"
import { PageLink } from "components/common/links/pageLink/pageLink"
import { ScenarioType } from "services/sicalcApi/sharedEntities/scenarioType"
import { ForbiddenResponseError } from "services/sicalcApi/errors/ForbiddenResponseError"
import { AppEditionFilter } from "components/appEditionFilter/appEditionFilter"
import { SidebarBuildingList } from "components/calculationTask/sidebarBuildingList/sidebarBuildingList"
import { BuildingResponse } from "services/sicalcApi/responses/buildingResponse"


export interface CalculationTaskSetupViewProps {
  calculationTask: CalculationTaskResponse
  onCalculationStarted: () => void
  onReset: () => void
}

export const CalculationTaskSetupView = (props: CalculationTaskSetupViewProps) => {
  const [ noiseSources, setNoiseSources ] = useState<NoiseSourceResponse[]>()
  const [ mapLocation, setMapLocation ] = useState<LatLongPoint>()
  const [ availableScenarios, setAvailableScenarios ] = useState<NoiseSourceResponse_Scenario[]>()
  const [ buildings, setBuildings ] = useState<BuildingResponse[]>([])
  const [ editingName, setEditingName ] = useState(false)

  const calculationPointsFormValuesRef = useRef<LatLongPoint[]>()
  const calculationAreaFormValuesRef = useRef<CalculationArea>()
  const [customScenarios, setCustomScenarios] = useState<CustomScenarioResponse[]>()

  const { noiseSourceApi, calculationTaskApi, customScenarioApi } = useSicalcApiService()
  const mapService = useMapService()
  const { showModal } = useModalService()

  const refreshNoiseSources = useCallback(async () => {
    const response =  await noiseSourceApi.getAll()
    setNoiseSources(response.noiseSources)
  }, [ noiseSourceApi ])

  const refreshCustomScenarios = useCallback(async () => {
    const response = await customScenarioApi.getAll(props.calculationTask.id)
    setCustomScenarios(response.customScenarios)
  }, [customScenarioApi, props.calculationTask.id])

  useEffect(() => {
    refreshNoiseSources()
  }, [ refreshNoiseSources ])

  useEffect(() => {
    if (props.calculationTask?.noiseSource) {
      setMapLocation({
        latitude: props.calculationTask.noiseSource.latitude,
        longitude: props.calculationTask.noiseSource.longitude,
      })
    }
  }, [ props.calculationTask ])

  useEscapeKeyHandler(mapService.cancelDrawing)
  const initialValues: FormDataModel = {
    name: props.calculationTask.name ?? '',
    noiseSourceId: props.calculationTask.noiseSource?.id ?? '',
    includedScenarioIds: props.calculationTask.includedScenarios.map(s => s.id),
    calculationPoints: props.calculationTask.calculationPoints,
    areaSelectionMode: props.calculationTask.sicalcParameters.areaSelectionMode,
    calculationArea: props.calculationTask.calculationArea ?? undefined,
    calculationAltitude: props.calculationTask.sicalcParameters.altitude ?? undefined,
    calculationResolution: props.calculationTask.sicalcParameters.resolution ?? undefined,
    outputCoordinateSystem: props.calculationTask.sicalcParameters.outputCoordinateSystem,
    outputCoordinateSystemZone: props.calculationTask.sicalcParameters.outputCoordinateSystemZone,
    editCalculationPointDialogOptions: { ...props.calculationTask.editCalculationPointDialogOptions },
    reportSettings: { ...props.calculationTask.reportSettings },
    worstCaseScenario: props.calculationTask.worstCaseScenario,
  }

  const handleSubmission = async (values: FormDataModel) => {
    await saveForm(values)
    await calculationTaskApi.start(props.calculationTask.id)
    props.onCalculationStarted()
  }

  const form = useFormik({
    initialValues,
    validationSchema: generateValidationSchema(customScenarios),
    onSubmit: handleSubmission,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  })

  useEffect(() => {
    calculationPointsFormValuesRef.current = form.values.calculationPoints
  })

  useEffect(() => {
    calculationAreaFormValuesRef.current = form.values.calculationArea
  })
  const refreshAvailableScenarios = useCallback(async () => {
    if (form.values.noiseSourceId) {
      try {
        const response =  await noiseSourceApi.get(form.values.noiseSourceId)
        setAvailableScenarios(response.scenarios)
      } catch (error) {
        if (error instanceof ForbiddenResponseError)
          toast.error("Du har ikke tilgang til støykilden som er valgt for prosjektet.")
      }
    }
  }, [form.values.noiseSourceId, noiseSourceApi])

  useEffect(() => {
    refreshAvailableScenarios()
  }, [ refreshAvailableScenarios, form.values.noiseSourceId ])

  useEffect(() => {
    refreshCustomScenarios()
  }, [ refreshCustomScenarios ])

  const handleNoiseSourceChanged = (newNoiseSourceId: any) => {
    const handleChange = async (newNoiseSourceId: any) => {
      await form.setFieldValue('noiseSourceId', newNoiseSourceId)
      // When the noise source is changed, ensure no
      // about-to-be-invalid scenarios remain selected
      await form.setFieldValue('includedScenarioIds', [])

      if (newNoiseSourceId) {
        const noiseSource = await noiseSourceApi.get(newNoiseSourceId)
        setMapLocation({
          latitude: noiseSource.latitude,
          longitude: noiseSource.longitude,
        })
      }
    }
    handleChange(newNoiseSourceId)
  }

  const saveForm = async (values: FormDataModel) => {
    const request: UpdateCalculationTaskRequest = {
      name: values.name !== undefined ? values.name : null,
      noiseSourceId: values.noiseSourceId,
      includedScenarios: values.includedScenarioIds.map((id: string) => ({ id })),
      calculationPoints: values.calculationPoints,
      sicalcParameters: {
        areaSelectionMode: values.areaSelectionMode,
        resolution: values.calculationResolution ?? null,
        altitude: values.calculationAltitude ?? null,
        outputCoordinateSystem: values.outputCoordinateSystem,
        outputCoordinateSystemZone: values.outputCoordinateSystemZone,
      },
      calculationArea: values.calculationArea,
      editCalculationPointDialogOptions: {
        coordinateSystem: values.editCalculationPointDialogOptions.coordinateSystem,
        zone: values.editCalculationPointDialogOptions.zone,
      },
      reportSettings: {
        ...values.reportSettings,
      },
      worstCaseScenario: values.worstCaseScenario,
    }
    await calculationTaskApi.update(props.calculationTask.id, request)
  }

  const handleNameChanged = (newName: string | undefined) => {
    form.setFieldValue('name', newName)
    setEditingName(false)
  }

  const deleteCustomScenario = async (customScenarioId: string) => {
    await customScenarioApi.delete(props.calculationTask.id, customScenarioId)
    await refreshCustomScenarios()
  }

  const deleteCalculationPoint = ({ latitude, longitude }: {latitude: number, longitude: number}) => {
    // Caution: When this function is passed to the map renderer, it will enclose any
    // stale state it was created with. Therefore, we must use refs to get fresh state
    const calculationPoints = calculationPointsFormValuesRef.current
    if (calculationPoints === undefined) {
      return
    }

    const index = calculationPoints.findIndex(c => c.latitude === latitude && c.longitude === longitude)
    if (index === -1) {
      throw new Error("Tried to delete a calculation point that did not exist in state")
    }

    const newCalculationPoints = [...calculationPoints]
    newCalculationPoints.splice(index, 1)
    form.setFieldValue('calculationPoints', newCalculationPoints)
  }

  const handleMapClick = (event: MapClickedEvent) => {

  }

  const onNewCalculationAreaSelected = (calculationArea: CalculationArea) => {
    form.setFieldValue('calculationArea', calculationArea)
    form.setFieldValue('areaSelectionMode', AreaSelectionMode.selectedArea)
  }

  const handleAreaSelectionModeChanged = (newValue: AreaSelectionMode) => {
    form.setFieldValue( 'areaSelectionMode', newValue)
  }

  const onNewCalculationPointAdded = (calculationPoint: CalculationPoint) => {
    if (calculationPointsFormValuesRef.current) {
      form.setFieldValue('calculationPoints', [...calculationPointsFormValuesRef.current,calculationPoint])
    } else {
      form.setFieldValue('calculationPoints', [calculationPoint])
    }
  }

  const onNewCalculationPointsAdded = (calculationPoints: CalculationPoint[]) => {
    if (calculationPointsFormValuesRef.current) {
      form.setFieldValue('calculationPoints', [...calculationPointsFormValuesRef.current, ...calculationPoints])
    } else {
      form.setFieldValue('calculationPoints', [...calculationPoints])
    }
  }

  const onCalculationPointsEdited = (calculationPoints: CalculationPoint[]) => {
    form.setFieldValue('calculationPoints', [...calculationPoints])
  }

  const onBuildingsEdited = useCallback((buildings: BuildingResponse[]) => {
    setBuildings(buildings)
  }, [])

  const onScenarioSelectionStateChanged = (scenarioId: string, selected: boolean) => {
    const scenarioIds = [ ...form.values.includedScenarioIds ]
    const index = scenarioIds.indexOf(scenarioId)
    if (!selected) {
      if (index !== -1) {
        scenarioIds.splice(index, 1)
      }
    } else {
      if (index === -1) {
        scenarioIds.push(scenarioId)
      }
    }

    form.setFieldValue('includedScenarioIds', scenarioIds)
  }

  const updateWorstCaseScenario = (newValue: UpdateCalculationTaskRequest_WorstCaseScenarioSettings | null) => {
    form.setFieldValue('worstCaseScenario', newValue)

    if (newValue === null) {
      return
    }

    // Auto include the scenarios in the worst case scenario
    const scenarioIdsToAutoInclude: string[] = []
    if (newValue.firstScenarioType === ScenarioType.Scenario
      && !form.values.includedScenarioIds.includes(newValue.firstScenarioId)) {
      scenarioIdsToAutoInclude.push(newValue.firstScenarioId)
    }
    if (newValue.secondScenarioType === ScenarioType.Scenario
        && !form.values.includedScenarioIds.includes(newValue.secondScenarioId)) {
      scenarioIdsToAutoInclude.push(newValue.secondScenarioId)
    }
    form.setFieldValue('includedScenarioIds', [
      ...form.values.includedScenarioIds,
      ...scenarioIdsToAutoInclude,
    ])
  }

  const editCalculationPoints = async () => {
    const result = await showModal(context => (
      <ImportCalculationPointsModal
        modalContext={context}
        initialCalculationPoints={form.values.calculationPoints}
        coordinateSystem={form.values.editCalculationPointDialogOptions.coordinateSystem}
      />
    )) as ImportCalculationPointsModalResult

    if (result.success) {
      await form.setFieldValue('editCalculationPointDialogOptions', {
        ...form.values.editCalculationPointDialogOptions,
        coordinateSystem: result.coordinateSystem,
      })

      onCalculationPointsEdited(result.newCalculationPoints!)
    }
  }

  const onSridChanged = (newValue: {
    coordinateSystem: CoordinateSystem
    zone: number | null
  }) => {
    form.setFieldValue('outputCoordinateSystem', newValue.coordinateSystem)
    form.setFieldValue('outputCoordinateSystemZone', newValue.zone)
  }

  const createCopy = async () => {
    try {
      const copy = await calculationTaskApi.createCopy(props.calculationTask.id)
      const confirmationJsx = (
        <span>
          <span>Opprettet kopi av beregningen</span>
          <Link
            to={"../calculation-tasks/" + copy.id}
            className="ml4"
          >
            <PageLink>åpne</PageLink>
          </Link>
        </span>
      )
      toast.success(confirmationJsx)
    } catch {
      toast.error("Kopieringen feilet")
    }
  }

  const reset = async () => {
    try {
      await calculationTaskApi.reset(props.calculationTask.id)
      toast.success("Beregningen ble tilbakestilt")
      props.onReset()
    } catch {
      toast.error("Kunne ikke tilbakestille beregningen")
    }
  }

  return (
    <FormikProvider value={form}>
      <BasePageLayout
        header={
          <PageHeader>
            <span className="row align-center">
              <CalculationTaskTitleEditor
                editing={editingName}
                initialName={form.values.name}
                onNameChanged={handleNameChanged}
                onCancelEdit={() => setEditingName(false)}
              />
              <HeaderContextMenu>
                <HeaderContextMenuButton onClick={() => setEditingName(true)}>
                  Gi nytt navn
                </HeaderContextMenuButton>
                <HeaderContextMenuButton onClick={reset}>
                  Tilbakestill
                </HeaderContextMenuButton>
                <HeaderContextMenuButton onClick={createCopy}>
                  Dupliser
                </HeaderContextMenuButton>
              </HeaderContextMenu>
            </span>
          </PageHeader>
        }
      >
        <CalculationTaskPageTemplate
          sidebar={
            <CalculationTaskPageSidebar
              breadcrumbs={(
                <CalculationTaskBreadCrumbs
                  calculationTask={props.calculationTask}
                  nameOverride={form.values.name}
                />
              )}
            >
              <label className="column gap2">
                <FormSectionHeading>Støykilde</FormSectionHeading>
                <SearchableSelectField
                  options={noiseSources?.map(n => ({
                    label: n.name,
                    value:n.id,
                  }))}
                  isLoading={false}
                  value={{
                    label: noiseSources?.find(n => n.id === form.values.noiseSourceId)?.name ?? '',
                    value: form.values.noiseSourceId,
                  }}
                  onChange={handleNoiseSourceChanged}
                />
                <div className="validation-error">
                  {form.errors.noiseSourceId}
                </div>
              </label>
              <AreaSelectionModePicker
                value={form.values.areaSelectionMode}
                onChange={handleAreaSelectionModeChanged}
                onClickMarkCalculationArea={mapService.drawCalculationArea}
                calculationTaskHasCalculationArea={!!form.values.calculationArea}
              />
              {form.touched.calculationArea && form.errors.calculationArea && (
                <div className="validation-error">
                  <i className="mdi mdi-alert-circle-outline"></i>
                  <span className="ml2">
                    {form.errors.calculationArea}
                  </span>
                </div>
              )}
              {form.values.areaSelectionMode === AreaSelectionMode.selectedArea && (
                <div className="column gap4">
                  <div className="column">
                    <span className="form-label">Høyde (m)</span>
                    <CalculationAltitudePicker
                      value={form.values.calculationAltitude ?? 4}
                      onChange={(value) => form.setFieldValue('calculationAltitude', value)}
                    />
                  </div>
                  <div className="column">
                    <span className="form-label">Oppløsning (m x m)</span>
                    <CalculationResolutionPicker
                      value={
                        form.values.calculationResolution
                        ?? props.calculationTask.noiseSource?.recommendedResolution
                        ?? GridResolution.G_09
                      }
                      onChange={(value) => form.setFieldValue('calculationResolution', value)}
                    />
                  </div>
                </div>
              )}
              <AppEditionFilter availableWhen={s => s.canCreateBuildings}>
                <SidebarBuildingList
                  calculationTaskId={props.calculationTask.id}
                  onBuildingsChanged={onBuildingsEdited}
                />
              </AppEditionFilter>

              <div className="column">
                <div className="row space-between align-center">
                  <FormSectionHeading>Beregningspunkter</FormSectionHeading>
                  <ClearButton
                    onClick={mapService.addCalculationPoints}
                    text="Legg til"
                    iconName={"plus"}
                  />
                </div>
                <div className="row gap4 mt4 mb4">
                  <ClearButton
                    iconName="open-in-new"
                    onClick={editCalculationPoints}
                  >Importer / rediger</ClearButton>
                </div>
                <CalculationPointSidebarList
                  calculationPoints={form.values.calculationPoints}
                  onClickDelete={deleteCalculationPoint}
                  onClickShowInMap={p => mapService.goToLocation(p)}
                />
              </div>

              <ScenarioPicker
                calculationTaskId={props.calculationTask.id}
                availableScenarios={availableScenarios}
                includedScenarioIds={form.values.includedScenarioIds}
                onScenarioSelectionStateChanged={onScenarioSelectionStateChanged}
                customScenarios={customScenarios}
                onClickDeleteCustomScenario={deleteCustomScenario}
                onCustomScenarioCreated={refreshCustomScenarios}
                worstCaseScenario={form.values.worstCaseScenario}
                onWorstCaseScenarioUpdated={updateWorstCaseScenario}
              />
              {form.touched.includedScenarioIds && form.errors.includedScenarioIds && (
                <div className="validation-error">
                  <i className="mdi mdi-alert-circle-outline"></i>
                  <span className="ml2">
                    {form.errors.includedScenarioIds}
                  </span>
                </div>
              )}

              <div className="column gap4">
                <FormSectionHeading>Leveranser</FormSectionHeading>
                <CheckboxInput
                  label={"Rapport"}
                  value={form.values.reportSettings.makeReport}
                  onChange={v => form.setFieldValue('reportSettings.makeReport', v)}
                />
                <CheckboxInput
                  label={"Støysonekart"}
                  value={true}
                  onChange={() => {}}
                  disabled={true}
                />
                <CheckboxInput
                  label={"Adresseliste for støyutsatte boliger"}
                  value={true}
                  onChange={() => {}}
                  disabled={true}
                />

                <SridInput
                  value={{
                    coordinateSystem: form.values.outputCoordinateSystem,
                    zone: form.values.outputCoordinateSystemZone,
                  }}
                  onChange={onSridChanged}
                />
              </div>

              <div className="bottom-aligned column justify-end">
                <BigActionButton onClick={form.handleSubmit}>
                  Start beregning
                </BigActionButton>
              </div>
            </CalculationTaskPageSidebar>
          }
        >
          <MapPane
            mapLocation={mapLocation}
            onMapClicked={(event) => handleMapClick(event)}
            calculationPoints={form.values.calculationPoints}
            buildings={buildings}
            calculationArea={
              form.values.areaSelectionMode === AreaSelectionMode.selectedArea
                ? form.values.calculationArea
                : undefined
            }
            onNewCalculationPoint={onNewCalculationPointAdded}
            onNewCalculationArea={onNewCalculationAreaSelected}
            onDeleteCalculationPointClicked={deleteCalculationPoint}
            showDrawingTools
          />
          <FormFieldAutoSaver onSave={saveForm}></FormFieldAutoSaver>
        </CalculationTaskPageTemplate>
      </BasePageLayout>
    </FormikProvider>
  )
}
