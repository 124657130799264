import './sidebarBuildingList.scoped.scss'
import { FormSectionHeading } from "components/common/headings/formSectionHeading/formSectionHeading"
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import React, { useEffect, useState } from "react"
import {
  CreateOrEditBuildingModal,
  CreateOrEditBuildingModalResult,
} from "components/calculationTask/createOrEditBuildingModal/createOrEditBuildingModal"
import { toast } from "react-hot-toast"
import { useModalService } from "services/modal/modalService"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import { CreateOrUpdateBuildingRequest } from "services/sicalcApi/requests/createOrUpdateBuildingRequest"
import { BuildingResponse } from "services/sicalcApi/responses/buildingResponse"
import { StackedList } from "components/common/stackedList/stackedList"
import { StackedListItem } from "components/common/stackedListItem/stackedListItem"
import { ToolTip } from "components/common/toolTip/toolTip"
import { ConfirmationModal } from "components/common/modals/confirmationModal/confirmationModal"
import { useMapService } from "services/mapService/mapService"

export interface SidebarBuildingListProps {
  calculationTaskId: string
  onBuildingsChanged: (buildings: BuildingResponse[]) => void
}

export const SidebarBuildingList = (props: SidebarBuildingListProps) => {
  const { showModal } = useModalService()
  const { buildingApi } = useSicalcApiService()
  const mapService = useMapService()

  const [ buildings, setBuildings ] = useState<BuildingResponse[]>([])
  const [ initialDataLoaded, setInitialDataLoaded ] = useState(false)

  const createNewBuilding = async () => {
    const result = await showModal(context => (
      <CreateOrEditBuildingModal
        heading="Ny bygning/skjerm"
        modalContext={context}
      />
    )) as CreateOrEditBuildingModalResult
    if (result.success) {
      const request: CreateOrUpdateBuildingRequest = result.newBuilding
      const building = await buildingApi.create(props.calculationTaskId, request)
      const newBuildings = [...buildings, building]
      setBuildings(newBuildings)
      props.onBuildingsChanged(newBuildings)
    }
  }

  useEffect(() => {
    buildingApi.getAll(props.calculationTaskId)
      .then(response => {
        setBuildings(response.buildings)
        props.onBuildingsChanged(response.buildings)
        setInitialDataLoaded(true)
      })
  }, [buildingApi, props.onBuildingsChanged, props.calculationTaskId])

  const editBuilding = async (initialBuilding: BuildingResponse) => {
    const result = await showModal(context => (
      <CreateOrEditBuildingModal
        heading="Endre bygning/skjerm"
        modalContext={context}
        initialValue={initialBuilding}
      />
    )) as CreateOrEditBuildingModalResult
    if (result.success) {
      const request: CreateOrUpdateBuildingRequest = result.newBuilding
      const updatedBuilding = await buildingApi.update(props.calculationTaskId, initialBuilding.id, request)
      const index = buildings.findIndex(b => b.id === initialBuilding.id)
      const newBuildings = [...buildings]
      if (index !== -1) {
        newBuildings[index] = updatedBuilding
        setBuildings(newBuildings)
        props.onBuildingsChanged(newBuildings)
      }
    }
  }

  useEffect(() => {
    buildingApi.getAll(props.calculationTaskId)
      .then(response => {
        setBuildings(response.buildings)
        props.onBuildingsChanged(response.buildings)
        setInitialDataLoaded(true)
      })
  }, [buildingApi, props.onBuildingsChanged, props.calculationTaskId])

  const deletebuilding = async (building: BuildingResponse) => {
    const confirmed = await showModal(context =>
      <ConfirmationModal
        modalContext={context}
        heading="Vil du slette bygningen?"
        message={`Vil du slette ${building.name}?`}
        confirmButtonText="Slett"
        confirmButtonStyle="danger"
      />) as boolean

    if (!confirmed) {
      return
    }

    await buildingApi.delete(props.calculationTaskId, building.id)
    const index = buildings.findIndex(b => b.id === building.id)
    if (index !== -1) {
      const newBuildings = [...buildings]
      newBuildings.splice(index, 1)
      setBuildings(newBuildings)
      props.onBuildingsChanged(newBuildings)
    }
  }

  const onClickShowInMap = (building: BuildingResponse) => {
    mapService.fitBoundsInViewport(building.vertices)
  }

  return (
    <div className="column">
      <div className="row space-between align-center mb4">
        <FormSectionHeading>Bygninger og skjermer</FormSectionHeading>
        <ClearButton
          onClick={createNewBuilding}
          text="Legg til"
          iconName={"plus"}
        />
      </div>
      <div className="list-container">
        {!initialDataLoaded && (
          <div className="text-lighter">Laster ...</div>
        )}
        {initialDataLoaded && (
          <StackedList>
            {buildings.map((building, index) => (
              <StackedListItem key={index}>
                <div className="row space-between">
                  <span className="building-name">{building.name}</span>
                  <div className="toolbar">
                    <ToolTip content="Rediger">
                      <ClearButton
                        iconName="pencil"
                        onClick={() => editBuilding(building)}
                        ariaLabel="Rediger"
                      />
                    </ToolTip>
                    <ToolTip content="Slett">
                      <ClearButton
                        iconName="delete"
                        onClick={() => deletebuilding(building)}
                        ariaLabel="Slett"
                      />
                    </ToolTip>
                    <ToolTip content="Sentrer i kartet" defaultPosition="top-end">
                      <ClearButton
                        iconName="crosshairs"
                        onClick={() => onClickShowInMap(building)}
                        ariaLabel="Sentrer i kartet"
                      />
                    </ToolTip>
                  </div>
                </div>
              </StackedListItem>
            ))}
          </StackedList>
        )}
      </div>
    </div>
  )
}
