import 'components/calculationTask/createOrEditBuildingModal/buildingPreview/buildingPreview.scoped.scss'
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { useAppEditionService } from "services/appEdition/appEditionService"
import Leaflet, {
  LatLng,
  LatLngBounds,
  LatLngBoundsLiteral, map,
} from "leaflet"
import { GeometryType } from "services/sicalcApi/sharedEntities/geometryType"
import { Vertex } from "services/sicalcApi/sharedEntities/vertex"
import * as MarkerIcon2x from "leaflet/dist/images/marker-icon-2x.png"
import * as MarkerIcon from "leaflet/dist/images/marker-icon.png"
import * as MarkerShadow from "leaflet/dist/images/marker-shadow.png"


export interface BuildingViewerProps {
  geometryType: GeometryType
  vertices: Vertex[]
  facadePoints: Vertex[]
}

const markerOptions: Leaflet.IconOptions = {
  iconRetinaUrl: MarkerIcon2x.default,
  iconUrl: MarkerIcon.default,
  shadowUrl: MarkerShadow.default,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [11, 41],
}

export const BuildingPreview = (props: BuildingViewerProps) => {
  const appEditionService = useAppEditionService()
  const mapElementRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<Leaflet.Map>()
  const geometryLayerGroup = useMemo(() => Leaflet.layerGroup(), [])
  const facadePointsLayerGroup = useMemo(() => Leaflet.layerGroup(), [])

  const destroyMap = useCallback(() => {
    mapRef.current?.off()
    mapRef.current?.remove()
    mapRef.current = undefined
  }, [])

  const initializeMap = useCallback(() => {
    destroyMap()
    const {
      tileLayerUrl,
      attributionHtml,
    } = appEditionService.settings.map
    mapRef.current = Leaflet.map(mapElementRef.current!, {
      zoomControl: false,
      // dragging: false,
      // scrollWheelZoom: false,
    })
    Leaflet.tileLayer(tileLayerUrl, {
      attribution: attributionHtml,
    })
      .addTo(mapRef.current)

    mapRef.current.attributionControl.setPrefix(false)
    geometryLayerGroup.addTo(mapRef.current)
    facadePointsLayerGroup.addTo(mapRef.current)
  }, [appEditionService.settings.map, destroyMap, geometryLayerGroup])

  const drawBuilding = useCallback(() => {
    if (mapRef.current === undefined) {
      return
    }
    geometryLayerGroup.clearLayers()
    if (props.vertices.length === 0) {
      return
    }
    const latLongs = props.vertices.map(vertex =>
      new LatLng(vertex.latitude,vertex.longitude))

    let geometry: Leaflet.Polygon | Leaflet.Polyline
    if (props.geometryType === "polygon") {
      geometry = Leaflet.polygon(latLongs)
        .setStyle({
          weight: 1.2,
          color: '#283075',
          opacity: 1,
          fillColor: "#1b2db6",
          fillOpacity: 0.8,
        })
    } else if (props.geometryType === "polyline") {
      geometry = Leaflet.polyline(latLongs)
        .setStyle({
          color: '#283075',
          opacity: 1,
          weight: 3,
        })
    } else {
      throw new Error("Unknown geometry type: " + props.geometryType)
    }

    geometry.addTo(geometryLayerGroup)
    const bounds = new LatLngBounds(latLongs as unknown as LatLngBoundsLiteral)
    mapRef.current.fitBounds(bounds)
  }, [geometryLayerGroup, props.geometryType, props.vertices])


  const drawFacadePoints = useCallback(() => {
    facadePointsLayerGroup.clearLayers()
    for (const facadePoint of props.facadePoints) {
      const position = Leaflet.latLng(facadePoint.latitude, facadePoint.longitude)
      const options = {
        icon: Leaflet.icon({
          iconUrl: '/facade-point-icon.png',
          iconSize: [5, 5],
        }),
        title: "Fasadepunkt",
        alt: "Beregningspunkt på fasaden",
        riseOnHover: false,
        draggable: false,
      }
      const marker = Leaflet.marker(position, options)
      marker.addTo(facadePointsLayerGroup)
    }
  }, [facadePointsLayerGroup, props.facadePoints])

  useEffect(() => {
    initializeMap()
    return destroyMap
  }, [appEditionService.settings.map, destroyMap, initializeMap])

  useEffect(() => {
    drawBuilding()
  }, [ drawBuilding ])

  useEffect(() => {
    drawFacadePoints()
  }, [ drawFacadePoints ])

  return (
    <div className="building-preview">
      <div className="map" ref={mapElementRef}></div>
    </div>
  )
}

