import papaParse from "papaparse"
import { Vertex } from "services/sicalcApi/sharedEntities/vertex"
import {
  UtmVertex,
} from "components/calculationTask/createOrEditBuildingModal/utmVertexInputFieldSet/utmVertexInputFieldSet"
import {
  altitudeValidator,
  eastingValidator,
  latitudeValidator,
  longitudeValidator,
  northingValidator,
} from "utils/validators/coordinateValidators"



export const parseGeographicCoordinates = (clipboardContent: string): Vertex[] | null => {
  const result = papaParse.parse<[number, number, number]>(clipboardContent, {
    skipEmptyLines: true,
  })
  const isValid = result.data.every(
    line =>
      Array.isArray(line)
      && line.length === 3
      && latitudeValidator.isValidSync(line[0])
      && longitudeValidator.isValidSync(line[1])
      && altitudeValidator.isValidSync(line[2]))

  if (!isValid) {
    return null
  }

  return result.data.map<Vertex>(v => ({
    latitude: v[0],
    longitude: v[1],
    altitude: v[2],
  }))
}

export const parseUtmCoordinates = (clipboardContent: string): UtmVertex[] | null => {
  const result = papaParse.parse<[number, number, number]>(clipboardContent, {
    skipEmptyLines: true,
  })

  const isValid = result.data.every(
    line =>
      Array.isArray(line)
      && line.length === 3
      && eastingValidator.isValidSync(line[0])
      && northingValidator.isValidSync(line[1])
      && altitudeValidator.isValidSync(line[2]))

  if (!isValid) {
    return null
  }

  return result.data.map<UtmVertex>(v => ({
    easting: v[0],
    northing: v[1],
    altitude: v[2],
  }))
}
