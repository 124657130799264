import * as yup from "yup"
import { FormikErrors } from "formik"
import { Vertex } from "services/sicalcApi/sharedEntities/vertex"
import {
  altitudeValidator,
  latitudeValidator,
  longitudeValidator,
  replaceDecimalSeparator,
} from "utils/validators/coordinateValidators"
import { ReferenceAltitude } from "services/sicalcApi/sharedEntities/referenceAltitude"
import { GeometryType } from "services/sicalcApi/sharedEntities/geometryType"



export interface BuildingFormDataModel {
  name: string
  geometryType: GeometryType
  referenceAltitude: ReferenceAltitude
  createFacadePointsAt4m: boolean
  createFacadePointsAt1_5m: boolean
  facadePointsMaxSpacing: number
  facadePointsDistanceFromFacade: number
  vertices: Vertex[]
}



// The error model with a dummy field. The field itself is added in the
// validation schema below using a magic string
export type CreateOrEditBuildingFormErrors = FormikErrors<BuildingFormDataModel & { allVertices: never }>

export const buildingValidationSchema = yup.object()
  .shape({
    name: yup.string()
      .required("Påkrevd")
      .max(70, "Maks 70 tegn"),
    vertices: yup.array()
      .of(yup.object()
        .shape({
          latitude: latitudeValidator,
          longitude: longitudeValidator,
          altitude: altitudeValidator,
        })),
    createFacadePointsAt1_5m: yup.boolean(),
    createFacadePointsAt4m: yup.boolean(),
    facadePointsMaxSpacing: yup.number()
      .transform(replaceDecimalSeparator)
      .required("Påkrevd")
      .typeError("Må være et tall")
      .positive("Må være større enn null"),
    facadePointsDistanceFromFacade: yup.number()
      .transform(replaceDecimalSeparator)
      .required("Påkrevd")
      .typeError("Må være et tall")
      .min(0, "Må være positiv"),
    geometryType: yup.string(),
    referenceAltitude: yup.string(),
  })
  .test(
    "mustHaveEnoughVertices",
    (value, context) => {
      const { vertices, geometryType } = value
      if (geometryType === "polyline"
        && vertices !== undefined && vertices.length < 2
      ) {
        return context.createError({
          path: "allVertices",
          message: "En skjerm består av minst to punkter",
        })
      }
      if (geometryType === "polygon"
        && vertices !== undefined && vertices.length < 3
      ) {
        return context.createError({
          path: "allVertices",
          message: "En bygning består av minst tre punkter",
        })
      }
      return true
    })
