import Leaflet, {
  LatLng,
  LatLngBounds,
  LatLngBoundsLiteral,
  LeafletMouseEvent,
} from "leaflet"
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import {
  CalculationArea,
  CalculationPoint,
  LatLongPoint,
  MapClickedEvent,
} from "components/calculationTask/mapPane/mapPane"
import * as MarkerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import * as MarkerIcon from 'leaflet/dist/images/marker-icon.png'
import * as MarkerShadow from 'leaflet/dist/images/marker-shadow.png'
import {
  BuildingResult,
  CalculationResultResponse,
  FacadePointResult,
  PointResult,
  ScenarioResult,
} from "services/sicalcApi/responses/calculationResultResponse"
import { BuildingResponse } from "services/sicalcApi/responses/buildingResponse"

const markerOptions: Leaflet.IconOptions = {
  iconRetinaUrl: MarkerIcon2x.default,
  iconUrl: MarkerIcon.default,
  shadowUrl: MarkerShadow.default,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [11, 41],
}

const drawMarkerIcon = Leaflet.icon({
  ...markerOptions,
  className: "map__cursor-crosshair",
})

const markerIcon = Leaflet.icon({
  ...markerOptions,
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
})

interface MapRendererOptions {
  tileLayerUrl: string
  attribution: string
  onMapClicked?: (e: MapClickedEvent) => void
  onNewCalculationArea?: (calculationArea: CalculationArea) => void
  onNewCalculationPoint?: (calculationPoint: LatLongPoint) => void
  onDeleteCalculationPointClicked?: (calculationPoint: LatLongPoint) => void
  showDrawingTools?: boolean
}

export default class MapRenderer {
  private map!: Leaflet.Map
  private options: MapRendererOptions
  private calculationPointsLayer = Leaflet.layerGroup()
  private buildingsLayer = Leaflet.layerGroup()
  private calculationAreaLayer = Leaflet.layerGroup()
  private scenarioResultByScenarioId = new Map<string, ScenarioResult>()
  private scenarioLayerGroupByScenarioId = new Map<string, Leaflet.LayerGroup>()
  private resultLayerSelector: Leaflet.Control | undefined

  constructor(mapRoot: HTMLElement, options: MapRendererOptions) {
    this.options = options
    this.initializeMap(mapRoot)

    if (options.showDrawingTools) {
      this.initializeDrawing()
    }

    this.bindEventListeners()
  }

  private initializeMap(mapRoot: HTMLElement) {
    this.map = Leaflet.map(mapRoot)

    const tileLayerUrl = this.options.tileLayerUrl
    const attributionString = this.options.attribution

    Leaflet.tileLayer(tileLayerUrl, {
      attribution: attributionString,
    }).addTo(this.map)

    this.map.attributionControl.setPrefix(false)
  }

  private initializeDrawing() {
    const customTranslation = {
      tooltips: {
        firstVertex: 'Trykk for å markere beregningsområdet',
        finishRect: 'Trykk for å fullføre',
        placeMarker: 'Trykk for å plassere punktet',
      },
      actions: {
        cancel: "Avbryt",
        finish: "Ferdig",
      },
    }
    this.map.pm.setLang('sicalc' as Leaflet.PM.SupportLocales, customTranslation, 'no')

    this.map.pm.Toolbar.copyDrawControl('Rectangle', {
      name: 'CalculationArea',
      block: 'custom',
      title: 'Marker beregningsområde',
      actions: ['cancel'],
    })
    ;(this.map.pm.Draw as any).CalculationArea.setOptions({
      snappable: false,
      snapDistance: 1,
      pathOptions: {
        color: '#7b94c7',
      },
    })

    this.map.pm.Toolbar.copyDrawControl('Marker', {
      name: 'CalculationPoints',
      block: 'custom',
      title: 'Legg til beregningspunkt',
      actions: ['finishMode'],
      toggle: true,

    })
    ;(this.map.pm.Draw as any).CalculationPoints.setOptions({
      cursorMarker: false,
      snappable: false,
      snapDistance: 1,
      continueDrawing: true,
      tooltips: true,
      markerStyle: {
        icon: drawMarkerIcon,
      },
    })

    this.map.pm.addControls({
      position: 'topleft',
      drawControls: false,
      editControls: false,
      optionsControls: false,
      customControls: true,
      oneBlock: false,
    })

    this.map.on('pm:create', (e) => {
      // We want to render the newly created item manually
      this.map.removeLayer(e.layer)

      if (e.shape === 'CalculationArea') {
        if (this.options.onNewCalculationArea !== undefined) {
          const corners = (e.layer as any).getBounds()
          const northwest = corners.getNorthWest()
          const northeast = corners.getNorthEast()
          const southeast = corners.getSouthEast()
          const southwest = corners.getSouthWest()
          const calculationArea: CalculationArea = {
            northWest: {
              latitude: northwest.lat,
              longitude: northwest.lng,
            },
            northEast: {
              latitude: northeast.lat,
              longitude: northeast.lng,
            },
            southEast: {
              latitude: southeast.lat,
              longitude: southeast.lng,
            },
            southWest: {
              latitude: southwest.lat,
              longitude: southwest.lng,
            },
          }
          this.options.onNewCalculationArea(calculationArea)
        }
      }

      if (e.shape === 'CalculationPoints') {
        const latlng = (e.layer as any).getLatLng()
        const point: LatLongPoint = {
          latitude: latlng.lat,
          longitude: latlng.lng,
        }
        if (this.options.onNewCalculationPoint !== undefined) {
          this.options.onNewCalculationPoint(point)
        }
      }
    })

    this.map.on('pm:drawstart', (e) => {
      Leaflet.DomUtil.addClass(this.map.getContainer(),'map__cursor-crosshair')

      if (e.shape === 'CalculationArea') {
        this.map.removeLayer(this.calculationAreaLayer)
      }
    })

    this.map.on('pm:drawend', (e) => {
      Leaflet.DomUtil.removeClass(this.map.getContainer(),'map__cursor-crosshair')

      if (e.shape === 'CalculationArea') {
        this.map.addLayer(this.calculationAreaLayer)
      }
    })
  }

  public goTo(coordinates: LatLongPoint, zoom?: number) {
    this.map.setView([coordinates.latitude, coordinates.longitude], zoom)
  }

  public fitBoundsInViewport(coordinatePairs: LatLongPoint[], maxZoom?: number) {
    const latLongs = coordinatePairs.map(point =>
      new LatLng(point.latitude,point.longitude)) as unknown as LatLngBoundsLiteral
    const bounds = new LatLngBounds(latLongs)
    this.map.fitBounds(bounds, { maxZoom })
  }

  public destroy() {
    this.map.off()
    this.map.remove()
  }

  public drawCalculationArea() {
    this.map.pm.enableDraw('CalculationArea')
  }

  public addCalculationPoints() {
    this.map.pm.enableDraw('CalculationPoints')
  }

  public cancelDrawing() {
    this.map.pm.disableDraw()
  }

  public renderCalculationPoints(calculationPoints: CalculationPoint[]) {
    const newCalculationPointsLayer = Leaflet.layerGroup()
    calculationPoints.forEach(point => {
      const marker = this.createCalculationPointMarker(point)
      newCalculationPointsLayer.addLayer(marker)
    })

    this.map.removeLayer(this.calculationPointsLayer)
    this.calculationPointsLayer = newCalculationPointsLayer
    this.map.addLayer(this.calculationPointsLayer)
  }

  public renderCalculationArea(calculationArea?: CalculationArea) {
    this.map.removeLayer(this.calculationAreaLayer)
    const newCalculationAreaLayer = Leaflet.layerGroup()
    this.calculationAreaLayer = newCalculationAreaLayer

    if (calculationArea !== undefined) {
      const polygon = Leaflet.polygon(Object.values(calculationArea).map(p => [p.latitude, p.longitude]), {
        color: "#7b94c7",
        interactive: false,
      })
      newCalculationAreaLayer.addLayer(polygon)
    }

    this.map.addLayer(this.calculationAreaLayer)
  }

  public renderBuildings(buildings: BuildingResponse[], facadePointResults?: FacadePointResult[]) {
    const newBuildingsLayer = Leaflet.layerGroup()
    for (const building of buildings) {
      const geometry = this.createBuildingGeometry(building)
      geometry.bindPopup(layer => (
        `
        <div class="map__marker-popup row align-center gap2 no-wrap">
          <span>${building.name}</span>
        </div>
      `
      ))
      geometry.addTo(newBuildingsLayer)
    }

    this.map.removeLayer(this.buildingsLayer)
    this.buildingsLayer = newBuildingsLayer
    this.map.addLayer(this.buildingsLayer)
  }

  public renderResult(result: CalculationResultResponse) {
    const scenarioResults = [...result.scenarioResults, ...result.customScenarioResults]
    if (result.worstCaseScenarioResult !== null) {
      scenarioResults.push(result.worstCaseScenarioResult)
    }

    this.scenarioResultByScenarioId = new Map<string, ScenarioResult>()
    this.scenarioLayerGroupByScenarioId = new Map<string, Leaflet.LayerGroup>()
    scenarioResults.forEach(scenarioResult => {
      this.scenarioResultByScenarioId.set(scenarioResult.scenarioId, scenarioResult)
      this.scenarioLayerGroupByScenarioId.set(scenarioResult.scenarioId, new Leaflet.LayerGroup())
    })

    this.renderResultLayerSelector(result)
    this.renderNoiseAreas(result)
    this.renderPointResults(result)
    this.renderBuildingResults(result)
  }

  private renderResultLayerSelector(result: CalculationResultResponse) {
    if (this.resultLayerSelector !== undefined) {
      this.map.removeControl(this.resultLayerSelector)
    }

    const layersObject: Leaflet.Control.LayersObject = {}
    this.scenarioLayerGroupByScenarioId.forEach((layerGroup, scenarioId) => {
      const { scenarioName } = this.scenarioResultByScenarioId.get(scenarioId)!
      layersObject[scenarioName] = layerGroup
    })
    this.resultLayerSelector = Leaflet.control.layers(layersObject, undefined, {
      collapsed:false,
    })
    this.resultLayerSelector.addTo(this.map)

    // Add a title
    const layerSelectorNode = this.resultLayerSelector.getContainer()
    if (layerSelectorNode) {
      const title = Leaflet.DomUtil.create('span')
      title.innerHTML = "Støysonekart, T-1442/2021"
      title.classList.add("map__legend-title")
      layerSelectorNode.insertBefore(title, layerSelectorNode.firstChild)
    }

    // Add a legend
    if (layerSelectorNode) {
      const container = Leaflet.DomUtil.create('div', 'map__legend column', layerSelectorNode)
      const subHeading = Leaflet.DomUtil.create('span', 'map__legend-subheading', container)
      subHeading.innerHTML += "Tegnforklaring"
      const redZone = Leaflet.DomUtil.create('div', 'map__legend-item row', container)
      redZone.innerHTML += `<i class="map__legend-icon red">`
      redZone.innerHTML += `<span class="map__legend-text">Lden &gt; 62 dBA</span>`
      const yellowZone = Leaflet.DomUtil.create('div', 'map__legend-item row', container)
      yellowZone.innerHTML += `<i class="map__legend-icon yellow">`
      yellowZone.innerHTML += `<span class="map__legend-text">Lden &gt; 52 dBA</span>`
    }

    // Activate the first group by default
    // Note that React strict mode causes the layer to be added twice
    // in development mode, for some reason
    // TODO: Fix the default layer being added twice in dev
    if (result.scenarioResults.length) {
      const firstKey = result.scenarioResults[0].scenarioId
      this.scenarioLayerGroupByScenarioId.get(firstKey)!.addTo(this.map)
    } else if (result.customScenarioResults.length) {
      const firstKey = result.customScenarioResults[0].scenarioId
      this.scenarioLayerGroupByScenarioId.get(firstKey)!.addTo(this.map)
    }
  }

  private renderNoiseAreas(result: CalculationResultResponse) {
    const scenarioResults = [...result.scenarioResults, ...result.customScenarioResults]
    if (result.worstCaseScenarioResult) {
      scenarioResults.push(result.worstCaseScenarioResult)
    }
    scenarioResults.forEach(scenarioResult => {
      scenarioResult.noiseAreas.forEach(noiseArea => {
        noiseArea.polygons.forEach(polygon => {
          const corners = polygon.simplePolygons
            .map(sp => sp.points.map(p => new Leaflet.LatLng(p.latitude, p.longitude)))
          const polygons = Leaflet.polygon(corners)
            .setStyle({
              color: 'black',
              opacity: 1,
              weight: 1.2,
              fillColor: noiseArea.areaType.toString(),
              fillOpacity: 0.5,
            })
          const layerGroup = this.scenarioLayerGroupByScenarioId.get(scenarioResult.scenarioId)!
          polygons.addTo(layerGroup)
        })
      })
    })
  }

  public getMapCenter(): LatLongPoint {
    const center = this.map.getCenter()
    return {
      latitude: center.lat,
      longitude: center.lng,
    }
  }

  private renderPointResults(result: CalculationResultResponse) {
    const scenarioResults = [...result.scenarioResults, ...result.customScenarioResults]

    scenarioResults.forEach(scenarioResult => {
      scenarioResult.pointResults.forEach(pointResult => {
        const marker = this.createPointResultMarker(pointResult)
        this.scenarioLayerGroupByScenarioId.get(scenarioResult.scenarioId)!.addLayer(marker)
      })
    })
  }

  private renderBuildingResults(result: CalculationResultResponse) {
    const scenarioResults = [...result.scenarioResults, ...result.customScenarioResults]
    scenarioResults.forEach(scenarioResult => {
      scenarioResult.buildingResults.forEach(buildingResult => {
        const geometry = this.createBuildingGeometry(buildingResult)
        const popup = this.createBuildingResultPopup(buildingResult)
        geometry.bindPopup(popup)
        this.scenarioLayerGroupByScenarioId.get(scenarioResult.scenarioId)!.addLayer(geometry)
      })
    })
  }

  private createCalculationPointMarker(calculationPoint: CalculationPoint) {
    const position = Leaflet.latLng(calculationPoint.latitude, calculationPoint.longitude)
    const options = {
      icon: markerIcon,
      title: calculationPoint.name ?? "(uten navn)",
      alt: calculationPoint.name ?? "(uten navn)",
      riseOnHover: true,
      draggable: false,
    }
    const marker = Leaflet.marker(position, options)
    marker.bindPopup(this.buildCalculationPointPopup(calculationPoint))

    marker.on('popupopen', () => {
      const deleteButton = document.getElementsByClassName("marker-delete-button")
        .item(0) as HTMLInputElement
      deleteButton.addEventListener("click", () => {
        if (this.options.onDeleteCalculationPointClicked) {
          const position = marker.getLatLng()
          this.options.onDeleteCalculationPointClicked({
            latitude: position.lat,
            longitude: position.lng,
          })
        }
      })
    })
    return marker
  }

  private createPointResultMarker(pointResult: PointResult) {
    const position = Leaflet.latLng(pointResult.latitude, pointResult.longitude)
    const options = {
      icon: markerIcon,
      title: pointResult.name ?? "(uten navn)",
      alt: pointResult.name ?? "(uten navn)",
      riseOnHover: true,
      draggable: false,
    }
    const marker = Leaflet.marker(position, options)

    const popupContentRoot = document.createElement('div')
    popupContentRoot.classList.add("map__marker-popup", "column")
    popupContentRoot.innerHTML += `<span>${pointResult.name ?? '(uten navn)'}</span>`
    popupContentRoot.innerHTML += this.buildPointResultPopupNameValueRow("Lden", pointResult.lden)
    popupContentRoot.innerHTML += this.buildPointResultPopupNameValueRow("LAeq", pointResult.leq)
    popupContentRoot.innerHTML += this.buildPointResultPopupNameValueRow("LAmax", pointResult.lmaxS)
    popupContentRoot.innerHTML += this.buildPointResultPopupNameValueRow("MFN natt", pointResult.mfnNight)
    marker.bindPopup(popupContentRoot)

    return marker
  }

  private createBuildingResultPopup(buildingResult: BuildingResult) {
    const lden = buildingResult.facadePointResults
      .map(f => f.lden)
      .filter(v => v !== null)
      .reduce((acc, next) => Math.max(acc!, next!), 0)
    const leq = buildingResult.facadePointResults
      .map(f => f.leq)
      .filter(v => v !== null)
      .reduce((acc, next) => Math.max(acc!, next!), 0)
    const lmaxS = buildingResult.facadePointResults
      .map(f => f.lmaxS)
      .filter(v => v !== null)
      .reduce((acc, next) => Math.max(acc!, next!), 0)
    const mfnNight = buildingResult.facadePointResults
      .map(f => f.mfnNight)
      .filter(v => v !== null)
      .reduce((acc, next) => Math.max(acc!, next!), 0)
    const popupContentRoot = document.createElement('div')
    popupContentRoot.classList.add("map__marker-popup", "column")
    popupContentRoot.innerHTML += `<span class="map__marker-popup-heading">${buildingResult.name}</span>`
    popupContentRoot.innerHTML += `<span class="map__marker-popup-explanation mb1 text-lighter">Maksimumsverdier</span>`
    popupContentRoot.innerHTML += this.buildPointResultPopupNameValueRow("Lden", lden)
    popupContentRoot.innerHTML += this.buildPointResultPopupNameValueRow("LAeq", leq)
    popupContentRoot.innerHTML += this.buildPointResultPopupNameValueRow("LAmax", lmaxS)
    popupContentRoot.innerHTML += this.buildPointResultPopupNameValueRow("MFN natt", mfnNight)

    return popupContentRoot
  }

  buildCalculationPointPopup(calculationPoint: CalculationPoint) {
    return `
      <div class="map__marker-popup row align-center gap2 no-wrap">
        <span>${calculationPoint.name ?? '(uten navn)'}</span>
        <input type='button' value='Slett' class='marker-delete-button'/>
      </div>
    `
  }
  buildPointResultPopupNameValueRow(name: string, value: number | null | undefined, unit: string = 'dB') {
    return `
      <div class="map__marker-popup-unit-value-row row align-center no-wrap">
        <span class="map__marker-popup-name">${name}</span>
        <span class="map__marker-popup-value">${value ? value.toFixed(1) : "–"}</span>
        <span class="map__marker-popup-unit">${value ? unit : ""}</span>
      </div>
    `
  }

  private bindEventListeners() {
    if (this.options.onMapClicked !== undefined) {
      this.map.on('click', (event: LeafletMouseEvent) => {
        const mapClickedEvent: MapClickedEvent = {
          location: {
            latitude: event.latlng.lat,
            longitude: event.latlng.lng,
          },
        }
        this.options.onMapClicked!(mapClickedEvent)
      })
    }
  }

  private createBuildingGeometry(building: BuildingResponse) {
    const latLongs = building.vertices.map(vertex =>
      new LatLng(vertex.latitude,vertex.longitude))

    let geometry: Leaflet.Polygon | Leaflet.Polyline
    if (building.geometryType === "polygon") {
      geometry = Leaflet.polygon(latLongs)
        .setStyle({
          weight: 1.2,
          color: '#283075',
          opacity: 1,
          fillColor: "#1b2db6",
          fillOpacity: 0.8,
        })
    } else if (building.geometryType === "polyline") {
      geometry = Leaflet.polyline(latLongs)
        .setStyle({
          color: '#283075',
          opacity: 1,
          weight: 3,
        })
    } else {
      throw new Error("Unknown building geometry type: " + building.geometryType)
    }

    return geometry
  }
}
