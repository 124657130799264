import {
  createContext,
  FC,
  useContext,
} from "react"
import { CalculationTaskApi } from "./constituentApis/calculationTaskApi"
import { ProjectApi } from "./constituentApis/projectApi"
import { AuthenticationApi } from "./constituentApis/authenticationApi"
import { NoiseSourceApi } from "./constituentApis/noiseSourceApi"
import { CustomScenarioApi } from "./constituentApis/customScenarioApi"
import { NoiseCategoryApi } from "./constituentApis/noiseCategoryApi"
import { useAuthService } from "services/auth/authService"
import { SicalcClientFactory } from "services/sicalcApi/sicalcClient"
import { CoordinateConversionApi } from "services/sicalcApi/constituentApis/coordinateConversionApi"
import { BuildingApi } from "services/sicalcApi/constituentApis/buildingApi"

export interface SicalcApiService {
  calculationTaskApi: CalculationTaskApi
  coordinateConversionApi: CoordinateConversionApi
  projectApi: ProjectApi
  authenticationApi: AuthenticationApi
  noiseSourceApi: NoiseSourceApi
  customScenarioApi: CustomScenarioApi
  noiseCategoryApi: NoiseCategoryApi
  buildingApi: BuildingApi
}

const SicalcApiServiceContext = createContext<SicalcApiService | undefined>(undefined)

export const SicalcApiServiceProvider: FC = ({ children }) => {
  const authService = useAuthService()
  const client = new SicalcClientFactory(authService).create()
  const sicalcApiService: SicalcApiService = {
    calculationTaskApi: new CalculationTaskApi(client),
    coordinateConversionApi: new CoordinateConversionApi(client),
    projectApi: new ProjectApi(client),
    authenticationApi: new AuthenticationApi(client),
    noiseSourceApi: new NoiseSourceApi(client),
    customScenarioApi: new CustomScenarioApi(client),
    noiseCategoryApi: new NoiseCategoryApi(client),
    buildingApi: new BuildingApi(client),
  }

  return (
    <SicalcApiServiceContext.Provider value={sicalcApiService}>
      {children}
    </SicalcApiServiceContext.Provider>
  )
}

export const useSicalcApiService = () => {
  const context = useContext<SicalcApiService | undefined>(SicalcApiServiceContext)
  if (!context) {
    const serviceName = Object.keys({ SicalcApiServiceContext })[0]
    throw new Error(serviceName + " was not provided. "
      + "Make sure the component is a child of the required service provider")
  }
  return context
}
